import terms from 'assets/terms'
import { RequestSignal } from 'services'

import './Requests.scss'
import RequestItem from './RequestItem'

export default function Requests() {
  const requests = RequestSignal.value

  return (
    <div className="requests hide-scroll">
      <div className="list">
        <div className="head request">
          <p className="name">{terms.Pages.Home.requests.name}</p>
          <p className="status">{terms.Pages.Home.requests.status}</p>
          <p className="date">{terms.Pages.Home.requests.lastUpdate}</p>
          <div className="actions-status" />
          <div className="actions-request" />
        </div>
        {requests.map(request => <RequestItem key={request.id} request={request} />)}
      </div>
    </div>
  )
}
