import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import terms from 'assets/terms'
import { TextInput } from 'components/inputs'
import { useState } from 'react'
import { Button, ButtonSize, ButtonStyle, ToastLevel, ToastSignal } from 'components'
import { GedGroupSignal, postGroup } from 'services'
import { ModalSignal } from '../ModalWrapper'

import './CreateGroupModal.scss'

export default function CreateGroupModal() {
  const [name, setName] = useState('')
  const [autoComplete, setAutoComplete] = useState<string[]>([])

  // ignore white space, case and accent
  const checkMatch = (value: string) => (group: string) => (
    group.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase().trim()
      .includes(
        value.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase().trim(),
      )
  )

  const handleClose = () => {
    ModalSignal.value = undefined
  }

  const handleSetName = (value: string) => {
    setAutoComplete(GedGroupSignal.list.value.filter(checkMatch(value)))
  }

  const handleSelectGroup = (group: string) => () => {
    setName(group)
    setAutoComplete([])
  }

  const handleCreateGroup = () => {
    // Create group
    if (!name) {
      ToastSignal.value = {
        message: 'Veuillez sélectionner un groupe dans la liste',
        severity: ToastLevel.WARNING,
      }
      return
    }

    postGroup(name)
    handleClose()
  }

  return (
    <>
      <h3>
        {terms.Modals.CreateGroup.title}
        <CloseIcon onClick={handleClose} />
      </h3>
      <p className="modal-info">
        {terms.Modals.CreateGroup.info}
      </p>
      <div className="create-group-auto-complete">
        <TextInput
          label="Nom du groupe"
          bindedValue={name}
          onChange={handleSetName}
        />
        {autoComplete.length > 1 && (
        <div className="results">
          {autoComplete.map(group => (
            <div key={group} className="item" onClick={handleSelectGroup(group)}>
              {group}
            </div>
          ))}
        </div>
        )}
      </div>
      <div className="actions flex-center">
        <Button
          style={ButtonStyle.borderLess}
          size={ButtonSize.medium}
          text={terms.Common.cancel}
          onClick={handleClose}
        />
        <Button
          size={ButtonSize.medium}
          text={terms.Common.create}
          onClick={handleCreateGroup}
        />
      </div>
    </>
  )
}
