/* eslint-disable react/no-danger */
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { Button, ButtonSize, ButtonStyle, ModalSignal } from 'components'
import terms from 'assets/terms'

type Props = {
  fn: () => Promise<void>
  info: string
}

export default function ConfirmModal({ fn, info }: Props) {
  const handleClose = () => {
    ModalSignal.value = undefined
  }

  const handleDelete = async () => {
    await fn()
    handleClose()
  }

  return (
    <>
      <h3>
        {terms.Modals.Confirm.title}
        <CloseIcon onClick={handleClose} />
      </h3>
      <p className="modal-info">
        {info}
      </p>
      <div className="actions flex-center">
        <Button
          style={ButtonStyle.borderLess}
          size={ButtonSize.medium}
          text={terms.Common.cancel}
          onClick={handleClose}
        />
        <Button
          size={ButtonSize.medium}
          text={terms.Common.confirm}
          onClick={handleDelete}
        />
      </div>
    </>
  )
}
