/* eslint-disable react/no-danger */
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { Button, ButtonSize, ButtonStyle, ModalSignal } from 'components'
import terms from 'assets/terms'
import { Request } from 'types'
import { startGedExport } from 'services'

type Props = {
  request: Request
}

export default function StartExportModal({ request }: Props) {
  const handleClose = () => {
    ModalSignal.value = undefined
  }

  const handleStartDownload = () => {
    startGedExport(request)
    handleClose()
  }

  return (
    <>
      <h3>
        {terms.Modals.Export.title}
        <CloseIcon onClick={handleClose} />
      </h3>
      <p className="modal-info" dangerouslySetInnerHTML={{ __html: terms.Modals.Export.info(request.name) }} />
      <div className="actions flex-center">
        <Button
          style={ButtonStyle.borderLess}
          size={ButtonSize.medium}
          text={terms.Common.cancel}
          onClick={handleClose}
        />
        <Button
          size={ButtonSize.medium}
          text={terms.Common.export}
          onClick={handleStartDownload}
        />
      </div>
    </>
  )
}
