import { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { ModalSignal, ToastSignal } from 'components'
import { HomePage, AdminPage } from 'pages'
import { useRoles } from 'services'

export default function Router() {
  const location = useLocation()
  const { isAdmin } = useRoles()

  useEffect(() => {
    // reset root signals when changing route
    ModalSignal.value = undefined
    ToastSignal.value = { ...ToastSignal.value, timeout: 0 }
  }, [location.pathname])

  return (
    <Routes>
      <Route element={<HomePage />} path="/" />
      {isAdmin && <Route element={<AdminPage />} path="/admin" />}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
