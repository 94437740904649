/* eslint-disable no-nested-ternary */
import { useState } from 'react'
import { useMountEffect } from 'utils'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg'
import { ReactComponent as ErrorIcon } from 'assets/icons/error-check.svg'
import terms from 'assets/terms'
import { getFolderSize, getRequests, uploadExcel } from 'services'
import { Button, ButtonSize, ButtonStyle, ExcelModal, Loader } from 'components'
import { Request } from 'types'
import { ModalSignal } from '../ModalWrapper'

import './ExcelModal.scss'
import ExcelError from './ExcelError'

type Props = {
  request: Request
  files: File[]
}

export default function ExcelUpload({ request, files }: Props) {
  const file = files[0]
  const [loading, setLoading] = useState(true)
  const [hasError, setError] = useState('')
  const [uploadedCount, setUploadedCount] = useState(0)
  const [uploadedMb, setUploadedMb] = useState(0)

  const handleError = (error: string) => {
    ModalSignal.value = <ExcelError request={request} files={files} message={error} />
  }

  useMountEffect(() => {
    const uploadFiles = async () => {
      const [error] = await uploadExcel(request, files[0])

      if (error) {
        setError(error)
        setLoading(false)
        handleError(error)
        return
      }

      setUploadedCount(1)
      setUploadedMb(file.size / 1024 / 1024)
      setLoading(false)
    }

    uploadFiles()
  })

  const handleClose = () => {
    setLoading(false)
    getRequests()
    ModalSignal.value = undefined
  }

  const handleCancel = () => {
    ModalSignal.value = <ExcelModal request={request} />
  }

  return (
    <>
      <h3>
        {terms.Modals.Excel.title}
        <CloseIcon onClick={handleClose} />
      </h3>
      <div className="folder-info">
        <p className="files-count">{`${uploadedCount} / ${files.length} ${terms.Modals.Excel.uploaded}`}</p>
        {loading ? <Loader variant="small" /> : (!hasError ? <SuccessIcon /> : <ErrorIcon />)}
        <div>
          <b>{file.name}</b>
          <span>{`${Math.round(uploadedMb)} / ${getFolderSize(files)}  MB`}</span>
        </div>
      </div>
      {loading ? (
        <Button
          style={ButtonStyle.borderLess}
          size={ButtonSize.medium}
          text={terms.Common.cancel}
          onClick={handleCancel}
        />
      ) : (
        <Button
          size={ButtonSize.medium}
          text={!hasError ? terms.Common.validate : terms.Common.retry}
          onClick={() => (!hasError ? handleClose() : handleCancel())}
        />
      )}
    </>
  )
}
