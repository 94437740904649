/* eslint-disable no-promise-executor-return */
import { useEffect } from 'react'
import moment from 'moment'
import terms from 'assets/terms'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as DownloadFileIcon } from 'assets/icons/download-file.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { GedExportSignal, getExcel, getGedExport, getZipFiles } from 'services'
import { Request } from 'types'
import {
  Button, ButtonSize, ButtonStyle, DeleteRequestModal, ExcelModal, Loader, ModalSignal,
} from 'components'
import StartExportModal from 'components/modals/export/StartExportModal'

type Props = {
  request: Request
}

export default function RequestItem({ request }: Props) {
  const gedExport = GedExportSignal.value[request.id]

  const hanldeUpdateExportStatus = async () => {
    await new Promise(_ => setTimeout(_, 1000))
    await getGedExport(request, gedExport.id)
  }

  const handleImport = () => {
    ModalSignal.value = <ExcelModal request={request} />
  }

  const handleGedExport = () => {
    ModalSignal.value = <StartExportModal request={request} />
  }

  const handleDelete = () => {
    ModalSignal.value = <DeleteRequestModal request={request} />
  }

  useEffect(() => {
    if (gedExport?.status === 'PENDING' && gedExport?.progress < 100) {
      hanldeUpdateExportStatus()
    }
  }, [gedExport])

  const renderExporting = () => (
    <div className="export-info">
      <p>{terms.Pages.Home.requests.exporting}</p>
      <Loader variant="xsmall" />
      <p className="progress">{`${gedExport?.progress || 0}/${gedExport?.total || 0}`}</p>
    </div>
  )

  const defaultExportRender = () => (
    <div className="actions-status">
      <Button
        style={request.status !== 'CREATED' ? ButtonStyle.light : ButtonStyle.primary}
        text={terms.Pages.Home.requests[request.status !== 'CREATED' ? 'importAgain' : 'import']}
        size={ButtonSize.medium}
        onClick={handleImport}
      />
      {request.status !== 'CREATED' && (
      <Button
        text={terms.Pages.Home.requests.downloadLastEp}
        size={ButtonSize.medium}
        onClick={getExcel(request, true)}
      />
      )}
      {request.status !== 'CREATED' && (
        <Button
          style={ButtonStyle.secondary}
          text={terms.Pages.Home.requests.export}
          size={ButtonSize.medium}
          onClick={handleGedExport}
          disabled={request.status === 'EP_ERROR'}
        />
      )}
    </div>
  )

  const renderExportInfo = () => {
    if (request.status === 'EXPORTING') return renderExporting()
    return defaultExportRender()
  }

  const getRequestClass = () => {
    if (request?.status === 'EXPORTED') return 'success'
    if (['EXPORT_ERROR', 'EP-ERROR'].includes(request?.status)) return 'error'
    return ''
  }

  return (
    <div key={request.id} className={`request ${getRequestClass()}`}>
      <Button
        style={ButtonStyle.borderLess}
        text={terms.Pages.Home.requests.downloadEp}
        icon={<DownloadIcon />}
        onClick={getExcel(request, false)}
      />
      <p className="name">{request.name}</p>
      <p className="status">{terms.Pages.Home.requests.statusList[request.status]}</p>
      <p className="date">{moment(request.last_modified).format('DD/MM/YYYY')}</p>
      {renderExportInfo()}
      <div className="actions-request">
        <Button
          text=""
          style={ButtonStyle.borderLess}
          icon={<DownloadFileIcon />}
          onClick={getZipFiles(request)}
        />
        <Button
          text=""
          style={ButtonStyle.borderLess}
          icon={<DeleteIcon />}
          onClick={handleDelete}
        />
      </div>
    </div>
  )
}
