/* eslint-disable react/no-danger */
import { ChangeEvent, DragEvent, useRef } from 'react'
import terms from 'assets/terms'
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg'
import { ReactComponent as FileIcon } from 'assets/icons/file.svg'
import { ToastLevel, ToastSignal } from 'components/toast/ToastMessage'

import './DropUpload.scss'

type Props = {
  onError: (error: string, files?: File[]) => void
  onUploadReady: (files: File[]) => void
}

export default function FileUpload({ onError, onUploadReady }: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleError = (error: string, files?: File[]) => {
    if (files?.length) onError(error, files)
    ToastSignal.value = {
      message: error,
      severity: ToastLevel.ERROR,
    }
  }

  const handleUpload = async (files: File[]) => {
    if (!files.length) return handleError(terms.Messages.Upload.noFilesFound)
    // if (files.some(file => !checkFileSize(file, 250))) return handleError(terms.Messages.Upload.fileSizeLimit, files)
    return onUploadReady(files)
  }

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const { items } = event.dataTransfer
    if (items.length > 1) return handleError(terms.Messages.Upload.onFileOnly)
    const files = Array.from(items).map(item => item.getAsFile())
    return handleUpload(files)
  }

  const handleInputFileChange = async (event: ChangeEvent<HTMLInputElement>) => (
    handleUpload(Array.from(event.target.files))
  )

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div className="drop-upload">
      <div
        className="drop-zone flex-column-center"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleInputFileChange}
          style={{ display: 'none' }}
          multiple={false}
        />
        <div className="icon">
          <FileIcon />
          <UploadIcon />
        </div>
        <p
          className="drop-info"
          dangerouslySetInnerHTML={{ __html: terms.Components.Upload.info('fichier') }}
        />
      </div>
    </div>
  )
}
