/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'
import {
  Accordion, Button, ButtonStyle, ConfirmModal, CreateGroupModal, Loader, ModalSignal, TextInput, ToolsWrapper,
} from 'components'
import terms from 'assets/terms'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { useMountEffect } from 'utils'
import {
  deleteGroup, GedGroupSignal, getGedGroups, getGroups, getRules, GroupSignal, postRule, RuleSignal,
} from 'services'
import { Rule } from 'types'

import './AdminPage.scss'

export default function AdminPage() {
  useMountEffect(getRules)
  useMountEffect(getGroups)
  useMountEffect(getGedGroups)

  const [filter, setFilter] = useState('')
  const navigate = useNavigate()
  const handleToggleRole = () => { navigate('/') }
  const globalRuleName = 'MIKI_ALL'
  const globalRuleId = GroupSignal.value.find(group => group.name === globalRuleName)?.id
  const filterdGroups = GroupSignal.value.filter(({ name }) => name.toLowerCase().includes(filter.toLowerCase()))

  const handleFilterGroups = (value: string) => { setFilter(value) }
  const handleCreateGroup = () => { ModalSignal.value = <CreateGroupModal /> }
  const handleDeleteGroup = (id: string, name: string) => () => {
    ModalSignal.value = (
      <ConfirmModal
        fn={() => deleteGroup(id)}
        info={`Êtes-vous sûr de vouloir supprimer le groupe ${name} ? Les règles associées seront également supprimées.`}
      />
    )
  }

  // Update the value of a rule and update the signal (<- triggering a re-render)
  const handleUpdateValue = (rule: Rule) => (value: Rule['value']) => {
    rule.value = value
    RuleSignal.value = [...RuleSignal.value]
  }

  // Add an empty rule to the signal
  const addEmptyRule = (group: string, type: Rule['kind']) => () => {
    RuleSignal.value = [
      ...RuleSignal.value,
      {
        id: uuidV4(),
        group,
        kind: type,
      },
    ]
  }

  const renderInput = (rule: Rule) => {
    switch (rule?.kind) {
      case 'separator':
        return (
          <div className="inputs separator">
            <TextInput
              key={rule.id}
              label="Séparateur multi-valeurs"
              defaultValue={rule.value}
              onChange={value => { rule.value = value }}
            />
          </div>
        )
      case 'empty-value':
        return (
          <div className="inputs">
            <TextInput
              key={rule.id}
              label="Valeur pour vider une métadonnée"
              defaultValue={rule.value}
              onChange={value => { rule.value = value }}
            />
          </div>
        )
      case 'mandatory-columns':
        return (
          <div key={rule.id} className="mandatory-inputs inputs">
            <span>Colonnes obligatoires</span>
            {rule.value?.map((value, index) => value !== null && (
              <div key={`${rule.id}-${value}-${index}`} className="flex-center">
                <TextInput
                  placeholder="Nom de la colonne"
                  defaultValue={value}
                  onChange={newValue => { rule.value[index] = newValue }}
                />
                <Button
                  text=""
                  style={ButtonStyle.borderLess}
                  icon={<CloseIcon />}
                  onClick={() => handleUpdateValue(rule)(rule.value.map((val, i) => {
                    if (i === index) return null
                    return val
                  }))}
                />
              </div>
            ))}
            <Button
              text="Ajouter une colonne"
              onClick={() => handleUpdateValue(rule)([...(rule.value || []), ''])}
            />
          </div>
        )
      case 'accepted-values':
        return (
          <div key={`accepted-values-${rule.id}`} className="accepted-inputs inputs">
            <span>Valeurs autorisées par colonne</span>
            {rule.value?.map((value, index) => value && (
              <div key={`${rule.id}-column-${index}`} className="flex-center">
                <TextInput
                  defaultValue={Object.keys(value)[0]}
                  placeholder="Nom de la colonne"
                  onChange={newColumn => {
                    rule.value[index] = {
                      [newColumn]: Object.values(rule.value[index])[0] || [''],
                    }
                  }}
                />
                {Object.values(value)[0].map((val, i) => (
                  <TextInput
                    placeholder="Valeur"
                    key={`${rule.id}-column-${index}-value-${i}`}
                    defaultValue={val}
                    onChange={newValue => {
                      rule.value[index][Object.keys(rule.value[index])[0]][i] = newValue
                    }}
                  />
                ))}
                <Button
                  text="+"
                  onClick={() => handleUpdateValue(rule)(
                    rule.value.map((v, i) => (i === index
                      ? { ...v, [Object.keys(v)[0]]: [...v[Object.keys(v)[0]], ''] }
                      : v)),
                  )}
                />
                <Button
                  text=""
                  style={ButtonStyle.borderLess}
                  icon={<CloseIcon />}
                  onClick={() => handleUpdateValue(rule)(rule.value.map((val, i) => {
                    if (i === index) return null
                    return val
                  }))}
                />
              </div>
            ))}
            <Button
              text="Ajouter une colonne"
              onClick={() => handleUpdateValue(rule)(
                [...(rule.value || []), { '': [''] }],
              )}
            />
          </div>
        )
      case 'default-value':
        return (
          <div key={`default-value-${rule.id}`} className="default-inputs inputs">
            <span>Valeurs par défaut</span>
            {rule.value?.map((value, index) => value && (
              <div key={`${rule.id}-column-${index}`} className="flex-center">
                <TextInput
                  placeholder="Nom de la colonne"
                  defaultValue={Object.keys(value)[0]}
                  onChange={newColumn => {
                    rule.value[index] = {
                      [newColumn]: Object.values(rule.value[index])[0],
                    }
                  }}
                />
                <TextInput
                  placeholder="Valeur"
                  defaultValue={Object.values(value)[0]}
                  onChange={newValue => {
                    rule.value[index][Object.keys(rule.value[index])[0]] = newValue
                  }}
                />
                <Button
                  text=""
                  style={ButtonStyle.borderLess}
                  icon={<CloseIcon />}
                  onClick={() => handleUpdateValue(rule)(rule.value.map((val, i) => {
                    if (i === index) return null
                    return val
                  }))}
                />
              </div>
            ))}
            <Button
              text="Ajouter une colonne"
              onClick={() => handleUpdateValue(rule)(
                [...(rule.value || []), { '': '' }],
              )}
            />
          </div>
        )
      default: return null
    }
  }

  return (
    <div id="admin" className="page with-toolbar">
      <ToolsWrapper>
        <TextInput placeholder={terms.Pages.Admin.search} onChange={handleFilterGroups} />
        <div className="actions flex-center">
          <Button
            icon={!GedGroupSignal.list.value.length && <Loader variant="xsmall" />}
            text={!GedGroupSignal.list.value.length
              ? 'Chargement des groupes GED...'
              : terms.Pages.Admin.createGroup}
            onClick={handleCreateGroup}
            disabled={GedGroupSignal.list.value.length === 0}
          />
          <Button
            text={terms.Common.save}
            onClick={postRule}
            icon={<SaveIcon />}
          />
          <Button
            text={terms.Pages.Admin.toggle}
            onClick={handleToggleRole}
          />
        </div>
      </ToolsWrapper>
      <div className="content">
        <Accordion title={terms.Pages.Admin.globalRules}>
          <div className="group">
            <div className="flex-center all-rules">
              {renderInput(RuleSignal.value.find(rule => rule.kind === 'separator' && rule.group === globalRuleId)) || (
                <Button text="Ajouter la règle séparateur multi-valeurs" onClick={addEmptyRule(globalRuleId, 'separator')} />
              )}
              {renderInput(RuleSignal.value.find(rule => rule.kind === 'empty-value' && rule.group === globalRuleId)) || (
                <Button text="Ajouter la règle valeur pour vider une métadonnée" onClick={addEmptyRule(globalRuleId, 'empty-value')} />
              )}
            </div>
            <div className="rules inline">
              {renderInput(RuleSignal.value.find(rule => rule.kind === 'mandatory-columns' && rule.group === globalRuleId)) || (
              <Button text="Ajouter la règle colonne obligatoire" onClick={addEmptyRule(globalRuleId, 'mandatory-columns')} />
              )}
              {renderInput(RuleSignal.value.find(rule => rule.kind === 'default-value' && rule.group === globalRuleId)) || (
              <Button text="Ajouter la règle valeurs par défaut" onClick={addEmptyRule(globalRuleId, 'default-value')} />
              )}
            </div>
            {renderInput(RuleSignal.value.find(rule => rule.kind === 'accepted-values' && rule.group === globalRuleId)) || (
              <Button text="Ajouter la règle valeurs acceptées" onClick={addEmptyRule(globalRuleId, 'accepted-values')} />
            )}
          </div>
        </Accordion>
        {filterdGroups.filter(({ name }) => name !== globalRuleName).map(({ id, name }) => (
          <Accordion key={id} title={terms.Pages.Admin.rule(name)} defaultExpanded={false}>
            <div className="group">
              <DeleteIcon onClick={handleDeleteGroup(id, name)} />
              <div className="rules inline">
                {renderInput(RuleSignal.value.find(rule => rule.kind === 'mandatory-columns' && rule.group === id)) || (
                <Button text="Ajouter la règle colonne obligatoire" onClick={addEmptyRule(id, 'mandatory-columns')} />
                )}
                {renderInput(RuleSignal.value.find(rule => rule.kind === 'default-value' && rule.group === id)) || (
                <Button text="Ajouter la règle valeurs par défaut" onClick={addEmptyRule(id, 'default-value')} />
                )}
              </div>
              {renderInput(RuleSignal.value.find(rule => rule.kind === 'accepted-values' && rule.group === id)) || (
              <Button text="Ajouter la règle valeurs acceptées" onClick={addEmptyRule(id, 'accepted-values')} />
              )}
            </div>
          </Accordion>
        ))}
      </div>
    </div>
  )
}
