/* eslint-disable react/no-danger */
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { Button, ButtonSize, ButtonStyle, ModalSignal } from 'components'
import terms from 'assets/terms'
import { ReactComponent as SuccessCheckIcon } from 'assets/icons/success-check.svg'
import { ReactComponent as ErrorCheckIcon } from 'assets/icons/error-check.svg'
import { Request } from 'types'
import { deleteRequests } from 'services'

import './DeleteRequestModal.scss'

type Props = {
  request: Request
}

export default function DeleteRequestModal({ request }: Props) {
  const info = request?.status === 'EXPORTED' ? terms.Modals.Delete.success : terms.Modals.Delete.error
  const icon = request?.status === 'EXPORTED' ? <SuccessCheckIcon /> : <ErrorCheckIcon />

  const handleClose = () => {
    ModalSignal.value = undefined
  }

  const handleDelete = async () => {
    await deleteRequests(request.id)
    handleClose()
  }

  return (
    <>
      <div className={`delete-banner flex-center ${request?.status === 'EXPORTED' ? 'success' : 'error'}`}>
        {icon}
      </div>
      <h3>
        {terms.Modals.Delete.title}
        <CloseIcon onClick={handleClose} />
      </h3>
      <p className="modal-info" dangerouslySetInnerHTML={{ __html: info }} />
      <div className="actions flex-center">
        <Button
          style={ButtonStyle.borderLess}
          size={ButtonSize.medium}
          text={terms.Common.cancel}
          onClick={handleClose}
        />
        <Button
          size={ButtonSize.medium}
          text={terms.Common.delete}
          onClick={handleDelete}
        />
      </div>
    </>
  )
}
